import './scripts/dropdown';
import popupGlowup from './scripts/popup';
// WC
import { showLinks } from './scripts/show-links';
import { actionAlert } from './wc/action-alert';
import { dhl } from './wc/dhl';
// import { productQuantityToggle } from './wc/wc-qty';

const { default: favourites } = require('./wc/favourites');

var documentReady = function () {
    // WC
    actionAlert();
    dhl();
    favourites();
    // productQuantityToggle();
    popupGlowup();

    showLinks();
};

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}
